import React from "react"
import { Helmet } from "react-helmet"

import OfferBase from "../../../components/offer"

const Offer = () => {
  return (
    <OfferBase offerTitle={'Chirurgia jamy ustnej'}>
      <Helmet title={"Chirurgia jamy ustnej - Centrum stomatologii Dent&Beauty | Świdnica"} />
    </OfferBase>
  )
}

export default Offer
